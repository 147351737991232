import React, { useEffect } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Layout from '../components/layout';
import { merge } from '../utils/functional';

import { refreshSessionId } from '../utils/session_id';
import { rhythm } from '../utils/typography';
import { colors } from '../components/colors';
import * as cssStyles from './terms.module.css';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: 'Privacy Policy' });




// width: 100% is required for IE to honor the .container bounds

const styles = {
    
    heading: {
        width: '100%',
        margin: `${rhythm(4.5)} 0 0`,
        fontFamily: '"Overpass", sans-serif',
        fontSize: '30px',
        fontWeight: 'bold',
        lineHeight: '1.87',
        color: colors.black
    },

    subHeading: {
        width: '100%',
        margin: '35px 0 0',
        fontFamily: '"Overpass", sans-serif',
        fontSize: '21px',
        fontWeight: '600',
        lineHeight: '1.43',
        color: colors.black
    },

    subSubHeading: {
        width: '100%',
        margin: '25px 0 0',
        fontSize: '18px',
        lineHeight: '1.5',
        letterSpacing: '0.16px',
        color: colors.black
    },

    body: {
        width: '100%',
        margin: '15px 0 0',
        fontSize: '15px',
        lineHeight: '1.93',
        letterSpacing: '0.15px',
        color: colors.black
    },

    link: {
        margin: '0 3px',
        color: colors.black,
        borderBottom: 'solid 1px ' + colors.lightGreen,
        textDecoration: 'none',
        boxShadow: 'none'
    },

    ul: {
        marginBottom: 0
    },

    li: {
        listStyleType: 'disc'
    },

    liSpacing: {
        margin: '0 0 15px'
    }
};


const Privacy = ({ location }) => {

    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
          location={ location } >                      

          <div
            style={{
                width: `100%`,
                overflow: `hidden`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `center`
            }} >

            <div
              className={ cssStyles.container }
              style={{
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `flex-start`,
                  alignItems: `flex-start`
              }} >

              <div
                style={ styles.heading } >
                Privacy Policy
              </div>

              <div
                style={
                    merge(styles.body,
                          { fontStyle: 'italic' }
                         )} >
                Last updated on Jan 15, 2023
              </div>

              <div
                style={ styles.subHeading } >
                Summary
              </div>

              <div
                style={ styles.body } >
                Your privacy is critically important to us at 6Pages. Integrity and openness are two of our company’s driving values. In that spirit, we’ve tried to make our Privacy Policy as straightforward and direct as possible.
              </div>

              <div
                style={ styles.body } >
                This Privacy Policy sets out how 6Pages, Inc. (“6Pages”, “we”, “our” or “us”) uses the personal information that we collect about you (“you”, “user”, “Subscriber” or “Member”) when you use our websites, applications, products, services or content (collectively, “Services”). It applies to anyone who interacts with any of our Services, including our Members and Subscribers who procure Services from us, anyone who signs up to receive email communications from us, anyone who visits a 6Pages-owned website, and anyone who interacts with us via email or on 3rd-party platforms such as social media.
              </div>

              <div
                style={ styles.body } >
                By interacting with our Services, you are deemed to have accepted our Privacy Policy. If you do not accept the Privacy Policy, we’ll be sad to see you go but you may not use our Services.
              </div>


              <div
                style={ styles.subHeading } >
                What we collect
              </div>

              <div
                style={ styles.subSubHeading } >
                Information we may collect from you
              </div>

              <div
                style={ styles.body } >
                <b>When you become a Member or Subscriber</b> (or otherwise procure Services from us), we will need some personal information to set up your account, accept payment and provide you with Services. Examples of information we may ask for include:
              </div>

              <div
                style={ styles.body } >
                
                <ul style={ styles.ul } >
                  
                  <li style={ merge(styles.li, styles.liSpacing) } >
                    Name
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    Email address
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    Mailing or billing address
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    Payment details
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    Phone number
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    Employer or company where you work
                  </li>

                  <li style={ styles.li } >
                    Your job title or role
                  </li>
                  
                </ul>
                
              </div>
              
              
              <div
                style={ styles.body } >
                <b>When you sign up for our email communications</b> (e.g. “3 Shifts Edition” email), we will collect your email address and use it to provide you with Services and contact you with updates. You may opt out of receiving communications from us by using the unsubscribe link provided in those communications or letting us know at
                <OutboundLink
                  style={ styles.link }
                  href='mailto:hello@6pages.com' >
                  hello@6pages.com
                </OutboundLink>
                . Please note that if you unsubscribe from a specific type of communication, we may still send you other notifications (e.g. password resets, important subscription updates).
              </div>

              <div
                style={ styles.body } >
                <b>When you contact us</b> (e.g. by phone, email or on 3rd-party platforms), we will collect any information that you choose to share and use it to review and respond to whatever it is you contacted us about.                    
              </div>



              <div
                style={ styles.subSubHeading } >
                Information we may collect automatically
              </div>

              <div
                style={ styles.body } >
                <b>Log information:</b> We collect information that web browsers, mobile devices, and servers typically make available, such as date and time of access, referring site, IP address, device identifiers, browser type, operating system, network information, and language preferences. We collect this information when you use our Services and may link it to the personal information you have provided us (e.g. to improve your experience or keep your account safe).
              </div>

              <div
                style={ styles.body } >
                <b>Usage information:</b> We collect information about your usage of our Services (e.g. what content you are reading, what links you click on). We may use it to help improve our service and extend relevant offers to you.
              </div>

              <div
                style={ styles.body } >
                <b>Location information:</b> We may determine the approximate location of your device from your IP address or other means such as your device’s GPS signal, nearby wifi access points or cell towers. The information may be used to, for instance, understand how many people visit our Services from certain geographic regions.
              </div>

              <div
                style={ styles.body } >
                <b>Information from cookies</b> (and other tracking technologies): 6Pages uses cookies and other common tracking technologies (e.g. pixel tags) to help us understand who is using our Services and how. We may use the information to identify visitors, track usage, and follow preferences for our Services, as well as understand the effectiveness of our emails and deliver relevant offers. For more information, please see our Cookie Policy below.
              </div>



              <div
                style={ styles.subHeading } >
                Our Cookie Policy
              </div>

              <div
                style={ styles.body } >
                This policy details how cookies are used by 6Pages, our partners, and users of our Services, as well as the options you have to control them. By using our site, you agree to our use of cookies.
              </div>

              <div
                style={ styles.body } >
                A cookie is a small text file that is stored on your computer or other device by your browser when you visit a website. When you return, your browser can provide the cookie to the website. Cookies are widely used to “remember” you and your preferences, either for a single visit (using a “session cookie”) or for multiple repeat visits (“persistent cookie”). They enable a consistent and efficient experience for visitors, and perform essential functions such as allowing users to register, log in to a website, and remain logged in.
              </div>

              <div
                style={ styles.body } >
                Cookies may be set by the site that you are visiting (known as “1st-party cookies”), or by other parties such as those who serve content or provide advertising or analytics services on the website (“3rd-party cookies”). You can adjust your cookie settings, including removing persistent cookies and changing the length of time that cookies are stored, through your web browser’s settings. (Learn more about deleting and controlling cookies at
                <OutboundLink
                  style={ styles.link }
                  href='https://aboutcookies.org/' >
                  AboutCookies.org
                </OutboundLink>
                .)
              </div>


              <div
                style={ styles.body } >
                6Pages uses 3 general types of cookies:
              </div>

              <div
                style={ styles.body } >
                
                <ol style={ styles.ul } >
                  
                  <li style={ styles.liSpacing } >
                    Essential – These cookies are essential for our Services to perform their basic functions. These include those required to allow registered users to authenticate and perform account-related functions, carry out functions such as fraud detection and security checks, or streamline your access to our Services.
                  </li>

                  <li style={ styles.liSpacing } >
                    Functional – These cookies are used to store preferences set by users, such as account name, language, and location.
                  </li>

                  <li>
                    Analytics and performance – These cookies collect information on how users interact with our websites, including what pages are visited most, as well as other analytical data. We use these details to understand how users interact with our Services and improve how they function.
                  </li>

                </ol>
                
              </div>

              <div
                style={ styles.body } >
                6Pages uses 1st-party
                <OutboundLink
                  style={ styles.link }
                  href='https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage' >
                  Google Analytics
                </OutboundLink>
                cookies to help us improve functionality and understand traffic and usage information for our Services. Information collected may include data that helps us identify users and unique visits, track website requests and clicks on links, and monitor performance. This information is also available to Google. You can learn more about how Google uses data by visiting the
                <OutboundLink
                  style={ styles.link }
                  href='https://policies.google.com/technologies/partner-sites' >
                  How Google uses data when you use our partners' sites or apps
                </OutboundLink>
                webpage. You can also opt out of Google Analytics by installing Google’s opt-out
                <OutboundLink
                  style={ styles.link }
                  href='https://tools.google.com/dlpage/gaoptout' >
                  browser add-on
                </OutboundLink>
                .
              </div>


              <div
                style={ styles.body } >
                Websites and emails may also contain other tracking technologies such as “pixel tags” (also known as “web beacons”). These are typically tiny transparent images placed on websites or emails, which provide information (e.g. time of visit, IP address, device type, operating system) when requested from a server, for similar purposes as cookies. They may be used in conjunction with cookies (e.g. to create or access cookies on your browser), though they are not cookies themselves and are not stored on your computer in the same way. If you disable cookies, pixel tags may still load but their functionality could be restricted.
              </div>

              <div
                style={ styles.body } >
                If you block or disable cookies, you may be unable to use many features of our Services including creation of and access to your 6Pages account.
              </div>
              


              <div
                style={ styles.subSubHeading } >
                Information we may collect from third parties
              </div>

              <div
                style={ styles.body } >
                We work with third parties such as payments platforms, analytics providers, and our corporate partners and clients. We may receive information about you from service providers and other third parties. If you would like to learn more about information we may receive from third parties, please contact us at
                <OutboundLink
                  style={ styles.link }
                  href='mailto:hello@6pages.com' >
                  hello@6pages.com
                </OutboundLink>
                .
              </div>



              <div
                style={ styles.subSubHeading } >
                Why we collect information
              </div>

              <div
                style={ styles.body } >
                We use information about you as mentioned above and for the purposes listed below:
              </div>

              <div
                style={ styles.body } >
                
                <ul style={ styles.ul } >
                  
                  <li style={ merge(styles.li, styles.liSpacing) } >
                    To provide our Services – e.g. to set up and maintain your subscription, send you email communications, or charge you for any of our paid Services;
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    To further develop and improve our Services – such as adding new features or creating new content that we think our users will enjoy;
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    To understand how users interact with our Services – e.g. monitoring performance and analyzing trends, which helps us improve our Services and make them easier to use;
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    To assess and improve the effectiveness of our offers and marketing – including understanding user adoption and retention;
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    To monitor and prevent problems with our Services – e.g. protect the security of our Services, detect and prevent fraudulent transactions and illegal activities, fight spam, and protect the rights and property of 6Pages and others (which may result in us declining a transaction or denying use of our Services);
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    To communicate with you – We may email you at times to keep you updated or ask for feedback. You can opt out of specific communications at any time (though we may still send important updates related to your account); and
                  </li>

                  <li style={ styles.li } >
                    To personalize your experience – e.g. content recommendations, relevant offers, language preferences.
                  </li>
                  
                </ul>
                
              </div>



              <div
                style={ styles.subSubHeading } >
                Legal bases for collecting and using information
              </div>

              <div
                style={ styles.body } >
                As it relates to EU data protection laws, our use of your information is based on the grounds that:
              </div>

              <div
                style={ styles.body } >
                
                <ul style={ styles.ul } >
                  
                  <li style={ merge(styles.li, styles.liSpacing) } >
                    The use is necessary in order to fulfill our commitments to you under the applicable terms of service or other agreements with you or is necessary to administer your account – for example, in order to enable access to our websites on your device or charge you for a paid plan; or
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    The use is necessary for compliance with a legal obligation; or
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    The use is necessary in order to protect your vital interests or those of another person; or
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    We have a legitimate interest in using your information – for example, to provide and update our Services; to improve our Services so that we can offer you an even better user experience; to assess and improve the effectiveness of our marketing; to safeguard and prevent any problems with our Services; to communicate with you; and to personalize your experience; or
                  </li>

                  <li style={ styles.li } >
                    You have given us your consent – for example, when providing your information to us to allow us to send you email communications, or by consenting before we place cookies on your device as described in our Cookie Policy.
                  </li>
                  
                </ul>
                
              </div>




              <div
                style={ styles.subHeading } >
                Storing and sharing information
              </div>

              <div
                style={ styles.subSubHeading } >
                How long we hold information
              </div>

              <div
                style={ styles.body } >
                We generally hold information about you for as long as required by the purposes for which we collect and use it — which are described in the sections above — or to the extent that we are legally required to keep it.
              </div>

              

              <div
                style={ styles.subSubHeading } >
                How we share information
              </div>

              <div
                style={ styles.body } >
                We share information about you in the limited circumstances spelled out below and with appropriate safeguards on your privacy:
              </div>

              <div
                style={ styles.body } >
                
                <ul style={ styles.ul } >
                  
                  <li style={ merge(styles.li, styles.liSpacing) } >
                    <b>Employees and independent contractors:</b> We may disclose information about you to our employees as well as individuals who are our independent contractors that need to know the information to help us provide our Services or process information on our behalf. We require our employees and independent contractors to follow this Privacy Policy for personal information that we share with them.
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    <b>3rd-party vendors:</b> We may employ other companies, their tools and platforms, and individuals to perform functions on our behalf (e.g. marketing support, customer service). We may share information about you with 3rd-party vendors who need to know information about you in order to provide their services. However, their use of information is limited to performing their function for us and not for any other purpose. This group includes vendors that help us provide our Services to you, such as payment providers that process your credit and debit card information, fraud prevention services that allow us to analyze fraudulent payment transactions, postal and email delivery services that help us stay in touch with you, and marketing and analytics service providers. We do not sell user names or email addresses to other companies so they can send unsolicited email or written promotional materials.
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    <b>Legal and regulatory requirements:</b> We may disclose information about you as required by law (e.g. in response to a subpoena, court order, or other governmental request) or to protect the rights, property or safety of ourselves, our users, or others.
                  </li>

                  <li style={ styles.li } >
                    <b>Business transitions:</b> In the event we go through a business transition such as a merger, acquisition by another company, or sale of all or a portion of our assets, your information may be among the assets transferred. You will be notified via prominent notice on our website of any significant change in ownership or business transition.
                  </li>

                </ul>
                
              </div>



              <div
                style={ styles.subSubHeading } >
                Security
              </div>

              <div
                style={ styles.body } >
                While no online service is 100% secure, we work very hard to protect information about you against unauthorized access, use, alteration, or destruction, and take reasonable measures to do so, such as monitoring our Services for potential vulnerabilities and attacks. That said, we cannot guarantee that third parties cannot access your information. It is a condition of your use of any 6Pages website that you agree we are not liable for any such disclosure.
              </div>


              <div
                style={ styles.subHeading } >
                Your choices and rights
              </div>

              <div
                style={ styles.subSubHeading } >
                Your choices
              </div>

              <div
                style={ styles.body } >
                You have several choices available when it comes to information about you:
              </div>


              <div
                style={ styles.body } >
                
                <ul style={ styles.ul } >
                  
                  <li style={ merge(styles.li, styles.liSpacing) } >
                    <b>Limit the information that you provide:</b> You can choose not to provide us with information such as email address, billing address, and payment information. Please keep in mind that if you do not provide this information, certain features of our Services – for example, paid subscriptions – may not be available.
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    <b>Opt out of communications:</b> You may opt out of receiving communications from us by using the unsubscribe link provided in those communications or letting us know at
                    <OutboundLink
                      style={ styles.link }
                      href='mailto:hello@6pages.com' >
                      hello@6pages.com
                    </OutboundLink>
                    . If you unsubscribe from a specific type of communication, we may still send you other notifications such as legal notices and important subscription updates.
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    <b>Set your browser to reject cookies:</b> On most browsers, you can choose to set your browser to remove and/or reject browser cookies. Please note that certain features of our Services may not function properly without the aid of cookies.
                  </li>

                  <li style={ styles.li } >
                    <b>Close your account:</b> While we’d be very sad to see you go, if you no longer want to use our Services, you can close your account. We may continue to retain certain information after your account is closed, such as information needed to reasonably comply with (or demonstrate compliance with) legal obligations or reasonably needed for our legitimate business interests.
                  </li>

                </ul>
                
              </div>


              <div
                style={ styles.subSubHeading } >
                Your rights (including CCPA and GDPR)
              </div>

              <div
                style={ styles.body } >
                If you are located in certain locations, including California in the United States under the California Consumer Privacy Act (“CCPA”) or countries that fall under the scope of the European General Data Protection Regulation (“GDPR”), data protection laws give you rights with respect to your personal data, subject to any exemptions provided by the law, including the rights to:
              </div>

              <div
                style={ styles.body } >
                
                <ul style={ styles.ul } >
                  
                  <li style={ merge(styles.li, styles.liSpacing) } >
                    Request access to and know about what information 6Pages collects about you, the sources of that information, and the intended use of the data collected; you also have the right to know if that information has been or will be shared, and with whom.
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    Request the correction or deletion of information that 6Pages holds on you; this right, however, may not apply in certain exceptions where 6Pages needs to retain information to perform business-related activities like provide our Services, detect or resolve security/functionality-related issues, comply with law enforcement, or generally carry out actions to serve the consumer as they might expect.
                  </li>

                  <li style={ merge(styles.li, styles.liSpacing) } >
                    Object to our use and processing of your personal data or request that we limit our use and processing of your data; certain exemptions may apply here in accordance with applicable law.
                  </li>

                  <li style={ styles.li } >
                    Request a portable and machine-readable version of your personal data that we have collected.
                  </li>

                </ul>
                
              </div>


              <div
                style={ styles.body } >
                If you have any questions or wish to exercise your CCPA or GDPR rights as a verifiable consumer, please submit a request to us at
                <OutboundLink
                  style={ styles.link }
                  href='mailto:hello@6pages.com' >
                  hello@6pages.com
                </OutboundLink>
                . 6Pages will not discriminate against consumers who have exercised their rights, and will not deny Services or charge different prices for Services to those consumers. EU individuals also have the right to make a complaint to a government supervisory authority.
              </div>


              <div
                style={ styles.subHeading } >
                Additional information
              </div>

              <div
                style={ styles.subSubHeading } >
                External links
              </div>

              <div
                style={ styles.body } >
                This Privacy Policy only applies to 6Pages’ use of your personal information. Our Services frequently contain links to 3rd-party sites that are not owned or controlled by us (each a “3rd-Party Service”). 6Pages is not responsible for the privacy policies or the content of any 3rd-Party Service and we recommend you read the privacy policies on each 3rd-Party Service that you interact with.
              </div>

              <div
                style={ styles.subSubHeading } >
                Changes to this policy
              </div>

              <div
                style={ styles.body } >
                Although most changes are likely to be minor, we may change our Privacy Policy from time to time (e.g. to account for changes in the law or changes in our Services). 6Pages encourages visitors to frequently check the Privacy Policy page on 6Pages.com for any changes to its Privacy Policy. If we make changes, we will notify you by revising the date at the top of this policy, and, in some cases, we may provide additional notice. Your continued use of our Services after a change to our Privacy Policy will be subject to the updated policy, and any information held will be governed by our most current privacy policy.
              </div>


              <div
                style={ styles.subSubHeading } >
                Contacting us
              </div>

              <div
                style={ styles.body } >
                If you have any questions about our Privacy Policy, please send us a note at
                <OutboundLink
                  style={ styles.link }
                  href='mailto:hello@6pages.com' >
                  hello@6pages.com
                </OutboundLink>
                .
              </div>

              <div
                style={ styles.subSubHeading } >
                Official information
              </div>

              <div
                style={ styles.body } >
                For the purposes of applicable data protection laws, including the EU General Data Protection Regulation and the Data Protection Act 2018, the “controller” of personal information collected through our websites is 6Pages, Inc., which can be contacted by email at
                <OutboundLink
                  style={ styles.link }
                  href='mailto:hello@6pages.com' >
                  hello@6pages.com
                </OutboundLink>
                and by mail at 6Pages, Inc. 1321 Upland Dr. PMB: 4667  Houston, TX 77043.
              </div>

              
              
              
              <div
                style={{
                    width: `1px`,
                    height: `115px`,
                    background: `transparent`
                }} />

            </div>
            
          </div>
          
        </Layout>
    );
};

export default Privacy;
